/*
 * Base structure
 */

html{
	
}

body {
	position: relative;
	padding-top: 0;
	padding-bottom: 0;
	color: theme-color('dark');
	background-color: $body-bg;
	height: 1010%;
	margin: 0;
}

a:not([href]):not([tabindex]) {
    cursor: pointer;
}

input[type="button"]{
    outline:none !important;
}

input[type="button"]::-moz-focus-inner {
    border: 0 !important;
}

button, select {
    outline:none !important;
}

input,select,textarea {
    border: 0;
    outline:none !important;
}

a {
    outline:none !important;
		&:hover {
			outline:none !important;
		}
		&:active {
			outline:none !important;
		}
		&:focus {
			outline:none !important;
		}
}