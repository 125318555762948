$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #E2EBF4 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #FF982E !default;
$yellow: #FFB816 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
$lightblue: #E2F4FF !default;

$colors: (
	"blue": $blue,
	"indigo": $indigo,
	"purple": $purple,
	"pink": $pink,
	"red": $red,
	"orange": $orange,
	"yellow": $yellow,
	"green": $green,
	"teal": $teal,
	"cyan": $cyan,
	"white": $white,
	"gray": $gray-600,
	"gray-dark": $gray-800,
	"lightblue": $lightblue
) !default;

$primary: $orange !default;
$secondary: $white !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

$theme-colors: ("primary": $primary,
	"secondary": $secondary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"light": $light,
	"dark": $dark
) !default;

$body-bg: $lightblue !default;
$body-color: $gray-900 !default;
$body-text-align: null !default;


$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px
) !default;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1320px
) !default;

$font-family-sans-serif:      "PT Sans",Roboto,"Helvetica Neue",Arial,Helvetica,sans-serif !default;
$font-family-monospace:       "PT Sans",Roboto,"Helvetica Neue",Arial,Helvetica,sans-serif !default;
$btn-font-family:  					  "PT Sans",Roboto,"Helvetica Neue",Arial,Helvetica,sans-serif !default;

$enable-rounded: false !default;